import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import type UserLinkProps from '@aurora/shared-client/components/users/UserLink/UserLinkProps';
import type { UserPageAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { RegistrationStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { UserViewFragment } from '../../../types/graphql-types';
import type {
  ExtendedOverlayInjectedProps,
  ExtendedOverlayTriggerInjectedProps
} from '../../extendedOverlayTrigger/ExtendedOverlayTrigger/ExtendedOverlayTrigger';
import ExtendedOverlayTrigger from '../../extendedOverlayTrigger/ExtendedOverlayTrigger/ExtendedOverlayTrigger';
import useTranslation from '../../useTranslation';
import UserHoverCard from '../UserHoverCard/UserHoverCard';

const log = getLog(module);

/**
 * Creates a NextJS link for a user
 *
 * @author Adam Ayres
 */
const UserLink: React.FC<React.PropsWithChildren<UserLinkProps>> = ({
  user,
  children,
  className,
  as = 'a',
  rawChildren,
  useHoverCard = true,
  passHref = false,
  ariaLabel,
  dataTestId = 'userLink',
  openInNewTab = false
}) => {
  const cx = useClassNameMapper();
  const { Link } = useEndUserRoutes();
  const { getCaseSensitivePath } = useSeoProperties();

  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.USER_LINK);

  if (textLoading) {
    return null;
  }

  if (!user) {
    log.error('No user specified, unable to create UserLink.');
    return <>{children}</>;
  }

  const { uid, login, registrationData, deleted } = user;
  const isAnonymous =
    registrationData?.status === RegistrationStatus.Anonymous || !(uid > -1) || deleted;

  if (!user.login && !isAnonymous) {
    log.error('No user login specified on user: %O, unable to create UserLink.', user);
    return <>{children}</>;
  }

  /**
   * Renders the HTML element representing the link. This is typically an `a` tag for a valid
   * user and a `span` for anonymous users.
   *
   * @param triggerProps trigger props used when the `useHoverCard` is enabled and the user is
   * not anonymous.
   */
  function renderLinkInternal(triggerProps: ExtendedOverlayTriggerInjectedProps = null) {
    const Component = isAnonymous && as === 'a' ? 'span' : as;
    const ariaLabelFinal =
      ariaLabel ?? formatMessage('authorName', { author: login ?? formatMessage('anonymous') });

    return (
      <Component
        /* eslint-disable react/jsx-props-no-spreading */
        {...triggerProps}
        data-testid={dataTestId}
        dangerouslySetInnerHTML={rawChildren ? { __html: rawChildren } : null}
        className={cx(className)}
        aria-label={ariaLabelFinal}
        target={openInNewTab ? '_blank' : '_self'}
      >
        {children}
      </Component>
    );
  }

  /**
   * Renders the link component. This provides the wrapping `Link` component for valid users, which
   * handles creating the `href` and passing it onto the actual element that is rendered for the link.
   *
   * @param triggerProps trigger props used when the `useHoverCard` is enabled and the user is
   * not anonymous.
   */
  function renderLink(triggerProps: ExtendedOverlayTriggerInjectedProps = null) {
    if (!isAnonymous) {
      return (
        <Link<UserPageAndParams>
          route={EndUserPages.UserPage}
          params={{ userId: uid?.toString(), login: getCaseSensitivePath(login) }}
          passHref={passHref}
        >
          {renderLinkInternal(triggerProps)}
        </Link>
      );
    }

    return renderLinkInternal();
  }

  function renderPopover({ onMouseEnter, onMouseLeave, ref, style }: ExtendedOverlayInjectedProps) {
    return (
      <UserHoverCard
        ref={ref}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        entity={user as UserViewFragment}
      />
    );
  }

  if (useHoverCard) {
    return <ExtendedOverlayTrigger overlay={renderPopover}>{renderLink}</ExtendedOverlayTrigger>;
  }
  return renderLink();
};

export default UserLink;
