import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/icons';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { UserKudosType } from '../../../types/enums';
import type { UserKudosFragment } from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './UserKudos.module.css';

interface Props {
  /**
   * The user for which kudos count is displayed for.
   */
  user: UserKudosFragment;

  /**
   * Whether to render either kudos given or received.
   */
  userKudosType: UserKudosType;

  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Class name(s) to apply to the icon element.
   */
  iconClassName?: string;

  /** Color to apply to the icon element */
  iconColor?: IconColor;

  /** Size to apply to the icon element */
  iconSize?: IconSize;

  /**
   * Include an icon in the component element.
   */
  useIcon?: boolean;
  /**
   * Include text in the component element.
   */
  useText?: boolean;
  /**
   * Class name(s) to apply to the span element.
   */
  textClassName?: string;
  /**
   * Display count and text in table format.
   */
  useTable?: boolean;
  /**
   * Set a custom element for kudos count component.
   */
  countElement?: React.ElementType;
  /**
   * Class name(s) to apply to the counts span element.
   */
  countClassName?: string;
}

/**
 * Displays kudos received or given by the user.
 *
 * @constructor
 *
 * @author Martin Sandoval
 */
const UserKudos: React.FC<React.PropsWithChildren<Props>> = ({
  user,
  as: Component = 'strong',
  countElement: KudosCountComponent = 'span',
  className,
  iconClassName,
  iconColor = IconColor.GRAY_600,
  iconSize = IconSize.PX_16,
  useText = true,
  useIcon = false,
  userKudosType,
  textClassName,
  useTable = false,
  countClassName
}) => {
  const cx = useClassNameMapper(localStyles);
  const {
    formatMessage,
    FormattedMessage,
    loading: textLoading
  } = useTranslation(EndUserComponent.USER_KUDOS);

  if (textLoading) {
    return null;
  }

  const { kudosGivenCount, kudosReceivedCount } = user;

  function renderUserKudos(): React.ReactElement {
    const kudosCount = userKudosType === UserKudosType.GIVEN ? kudosGivenCount : kudosReceivedCount;
    return userKudosType ? (
      <Component
        title={useTable ? formatMessage(`${userKudosType}Likes`) : formatMessage(userKudosType)}
        className={cx(className, { 'lia-kudos-count': useTable })}
      >
        {useIcon && (
          <Icon
            icon={Icons.LikeIcon}
            size={iconSize}
            color={iconColor}
            className={cx(iconClassName, 'lia-g-mr-5')}
          />
        )}
        {useText || useTable ? (
          <FormattedMessage
            id={`${userKudosType}CountWithSpan`}
            values={{
              count: kudosCount,
              kudosCountRender: function renderCount(): React.ReactNode {
                return (
                  useTable && (
                    <KudosCountComponent className={cx('lia-count-text', countClassName)}>
                      {kudosCount}
                    </KudosCountComponent>
                  )
                );
              },
              span: function renderChunks(): React.ReactNode {
                return (
                  <span className={cx(textClassName)}>
                    {useText
                      ? formatMessage(`${userKudosType}Count`, { count: kudosCount })
                      : formatMessage('kudosReceivedAsLikes', { count: kudosCount })}
                  </span>
                );
              }
            }}
          />
        ) : (
          <span className={cx('lia-count-text')}>{kudosCount}</span>
        )}
      </Component>
    ) : null;
  }

  return <>{renderUserKudos()}</>;
};

export default UserKudos;
