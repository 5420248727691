import type { NodeDescriptionFragment } from '@aurora/shared-generated/types/graphql-types';
import type { ClampLinesType } from '@aurora/shared-types/community/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { SharedComponent } from '../../../enums';
import useTranslation from '../../useTranslation';

interface Props {
  /**
   * The node to display the description for.
   */
  node: NodeDescriptionFragment;
  /**
   * Set a custom element for this component.
   */
  as?: React.ElementType;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * The number of lines to clamp.
   */
  clampLines?: ClampLinesType;
  /**
   * Whether HTML elements should be displayed or not.
   */
  useTextDescription?: boolean;
  /**
   * Node description snippet to be highlighted (to be only used for displaying search results)
   */
  descriptionSearchSnippet?: string;
}

/**
 * Node description.
 *
 * @constructor
 */
const NodeDescription: React.FC<React.PropsWithChildren<Props>> = ({
  node,
  as: Component = 'small',
  clampLines = 1,
  className,
  useTextDescription = true,
  descriptionSearchSnippet
}) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(SharedComponent.NODE_DESCRIPTION);

  if (textLoading) {
    return null;
  }

  const { description = '' } = node;
  const nodeDescription = formatMessage('description', { description });

  return description.trim().length > 0 ? (
    useTextDescription ? (
      <Component
        data-testid="NodeDescription"
        className={cx({ [`lia-g-clamp lia-g-clamp-${clampLines}`]: clampLines }, className)}
      >
        {descriptionSearchSnippet ? (
          <p
            className={cx('lia-g-mb-0')}
            dangerouslySetInnerHTML={{ __html: descriptionSearchSnippet }}
          ></p>
        ) : (
          nodeDescription
        )}
      </Component>
    ) : (
      <div
        data-testid="NodeDescription"
        className={cx({ [`lia-g-clamp lia-g-clamp-${clampLines}`]: clampLines }, className)}
        dangerouslySetInnerHTML={{ __html: nodeDescription }}
      />
    )
  ) : null;
};

export default NodeDescription;
