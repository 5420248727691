import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import {
  ActionButtonVariant,
  ButtonVariant,
  LoadingButtonVariant
} from '@aurora/shared-client/components/common/Button/enums';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useNodePolicies from '@aurora/shared-client/components/nodes/useNodePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { canModerateNode } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';
import {
  ModerationAction,
  ModerationStatus
} from '@aurora/shared-generated/types/graphql-schema-types';
import type { ContextMessageFragment } from '@aurora/shared-generated/types/graphql-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import dynamic from 'next/dynamic';
import React, { useContext, useState } from 'react';
import { Dropdown, useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type {
  MessageActionMenuFragment,
  ModerateMessageMutation,
  ModerateMessageMutationVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import markMessageAsApprovedMutation from '../MessageActionMarkAsNotAbuse/ModerateMessage.mutation.graphql';
import moderationDataQuery from '../MessageActionMarkAsSpam/MessageModerationData.query.graphql';

const ConfirmationDialog = dynamic(
  () => import('@aurora/shared-client/components/common/ConfirmationDialog/ConfirmationDialog'),
  { ssr: false }
);

const log = getLog(module);

interface Props {
  /**
   * Variant of the action button.
   */
  actionButtonVariant?: ActionButtonVariant;
  /**
   * Message object to be marked as approved.
   */
  message: MessageActionMenuFragment | ContextMessageFragment;
  /**
   * Classname(s) to apply to the component.
   */
  className?: string;
  /**
   * Callback function for toggling the display of subject overview modal.
   */
  onCloseSubjectModal?: () => void;
  /**
   * Callback function for hiding the subject overview modal.
   */
  onHideSubjectModal?: (isHideModal: boolean) => void;
}

/**
 * The action to approve a message.
 * @constructor
 *
 * @author Arjun Krishna
 */
const MessageActionMarkAsApproved: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  actionButtonVariant = ActionButtonVariant.DROP_DOWN_BUTTON,
  message,
  onCloseSubjectModal,
  onHideSubjectModal
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_MARK_AS_APPROVED
  );
  const { isAnonymous } = useRegistrationStatus();
  const uidSeed = useUIDSeed();
  const cx = useClassNameMapper();
  const { addToast } = useToasts();
  const client = useApolloClient();
  const { community } = useContext(AppContext);

  const [markMessageAsApproved] = useMutationWithTracing<
    ModerateMessageMutation,
    ModerateMessageMutationVariables
  >(module, markMessageAsApprovedMutation);

  const [frameEnd] = useFrameEnd();

  const { data: nodePolicies, loading: nodePoliciesLoading } = useNodePolicies(module, {
    id: community.id,
    useCanModerateNode: true
  });

  const canAccessModerationManagerPolicy = canModerateNode(nodePolicies?.coreNode);

  const [showCancelConfirm, setShowCancelConfirm] = useState<boolean>(false);

  if (isAnonymous || textLoading || nodePoliciesLoading || !canAccessModerationManagerPolicy) {
    return null;
  }

  const requiresApproval = message.moderationData?.status !== ModerationStatus.Approved;

  /**
   * Renders failure toast messages
   * @param alertVariant The variant for the alert message
   * @param title Title to be displayed
   * @param body Toast body
   * @param autohide whether the toast should be hidden automatically after certain time interval
   */
  function renderToast(
    alertVariant: ToastAlertVariant,
    title: string,
    body: string,
    autohide = true
  ): void {
    const toastVariant =
      alertVariant === ToastAlertVariant.DANGER ? ToastVariant.BANNER : ToastVariant.FLYOUT;
    const toastProps: ToastProps = {
      id: uidSeed(title),
      toastVariant,
      alertVariant,
      title,
      autohide,
      message: body,
      delay: 4000
    };
    addToast(toastProps);
  }

  /**
   * Action handler for marking a message as approved
   */
  async function handleAction(): Promise<void> {
    const mutationOptions: ModerateMessageMutationVariables = {
      messageId: message.id,
      action: ModerationAction.Approve
    };
    const parentFrameId = getParentFrameId();
    setShowCancelConfirm(false);
    const { errors } = await markMessageAsApproved({
      variables: mutationOptions,
      refetchQueries: [
        {
          query: moderationDataQuery,
          variables: {
            id: message.id
          }
        }
      ],
      context: { parentFrameId },
      onCompleted: (): void => {
        frameEnd({
          context: { parentFrameId }
        });
      }
    });

    if (errors?.length > 0) {
      log.error('Unable to approve message.', errors);
      renderToast(
        ToastAlertVariant.DANGER,
        formatMessage('unexpected.error.title'),
        formatMessage('unexpected.error.message')
      );
      return null;
    }
    if (actionButtonVariant === ActionButtonVariant.SUBJECT_MODAL_BUTTON) {
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    renderToast(
      ToastAlertVariant.SUCCESS,
      formatMessage('success.title'),
      formatMessage('success.message')
    );
    client.cache.evict({ id: client.cache.identify(message), broadcast: false });
    client.cache.gc();
    setShowCancelConfirm(false);
  }

  /**
   * Handles hide or cancel dialog action
   */
  function onHideOrCancelDialog() {
    if (actionButtonVariant === ActionButtonVariant.SUBJECT_MODAL_BUTTON) {
      /**
       * Timeout is set to prevent subjectModal from rendering again
       */
      setTimeout(() => onHideSubjectModal(false), 500);
      onCloseSubjectModal();
    }
    setShowCancelConfirm(false);
  }

  /**
   * Renders Confirmation Dialog.
   */
  function renderConfirmationDialog(): React.ReactElement {
    return (
      showCancelConfirm && (
        <ConfirmationDialog
          show={showCancelConfirm}
          onHide={(): void => onHideOrCancelDialog()}
          onSubmit={handleAction}
          onCancel={(): void => onHideOrCancelDialog()}
          titleText={formatMessage('modalTitle')}
          bodyText={formatMessage('modalText')}
          submitButtonType={LoadingButtonVariant.LOADING_BUTTON}
          submitButtonText={formatMessage('modalSubmit')}
        />
      )
    );
  }

  /**
   * Renders the button as a drop-down item.
   */
  function renderAsDropDownItemButton(): React.ReactElement {
    return (
      <Dropdown.Item
        onClick={() => setShowCancelConfirm(true)}
        data-testid="MessageActionMarkAsApproved"
      >
        {formatMessage('title')}
      </Dropdown.Item>
    );
  }

  /**
   * Renders the button as a toolbar button.
   */
  function renderAsToolbarButton(): React.ReactElement {
    return (
      <Button
        className={cx(className)}
        variant={ButtonVariant.PRIMARY}
        onClick={() => setShowCancelConfirm(true)}
        data-testid="MessageActionMarkAsApproved"
      >
        {formatMessage('title')}
      </Button>
    );
  }

  /**
   * Renders the button as a dashboard subject modal button.
   */
  function renderAsSubjectModalButton(): React.ReactElement {
    return (
      <Button
        data-testid="MessageActionMarkAsApproved"
        size="lg"
        variant={ButtonVariant.PRIMARY}
        onClick={() => {
          onHideSubjectModal(true);
          setShowCancelConfirm(true);
        }}
      >
        {formatMessage('title')}
      </Button>
    );
  }

  /**
   * Renders the Action button based on the ActionButtonVariant.
   */
  function renderActionButton(): React.ReactElement {
    switch (actionButtonVariant) {
      case ActionButtonVariant.TOOLBAR_BUTTON: {
        return renderAsToolbarButton();
      }
      case ActionButtonVariant.SUBJECT_MODAL_BUTTON: {
        return renderAsSubjectModalButton();
      }
      default: {
        return renderAsDropDownItemButton();
      }
    }
  }

  return (
    requiresApproval && (
      <>
        {renderConfirmationDialog()}
        {renderActionButton()}
      </>
    )
  );
};

export default MessageActionMarkAsApproved;
