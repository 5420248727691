import SsoRegistrationContext from '@aurora/shared-client/components/context/SsoRegistrationContext/SsoRegistrationContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type { MessageReplyPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { AuthFlow } from '@aurora/shared-client/types/enums';
import { LocalStorageKeys } from '@aurora/shared-types/community/enums';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { isPendingActionExpired } from '@aurora/shared-utils/helpers/anonymousUserActions/AnonymousUserActionsHelper';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useLocalStorage } from 'react-use';
import ConversationStyleBehaviorHelper from '../../../helpers/boards/ConversationStyleBehaviorHelper';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import type { AnonymousUserAction } from '../../../types';
import { AnonAction } from '../../../types/enums';
import useTranslation from '../../useTranslation';
import type { MessageActionType } from '../types';
import useAuthFlow from '@aurora/shared-client/components/useAuthFlow';
import useCommunitySsoProperties from '@aurora/shared-client/components/community/useCommunitySsoProperties';
import useCommunityPolicies from '@aurora/shared-client/components/community/useCommunityPolicies';
import { canReportAbuseDSA } from '@aurora/shared-client/helpers/nodes/NodePolicyHelper';

const ReportAbuseModal = dynamic(() => import('./ReportAbuseModal/ReportAbuseModal'));

/**
 * The action to report a message as abuse
 * @constructor
 * @author Deepak Yadav
 */
const MessageActionReportAbuse: React.FC<React.PropsWithChildren<MessageActionType>> = ({
  message
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_ACTION_REPORT_ABUSE
  );
  const { router, loading: routesLoading } = useEndUserRoutes();
  const [showReportTopicModal, setShowReportTopicModal] = useState<boolean>(false);
  const [, setMessageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const { isAnonymous, isFullyRegistered, isPartiallyRegistered } = useRegistrationStatus();
  const tenant = useContext(TenantContext);
  const { showSsoRegistrationModal } = useContext(SsoRegistrationContext);
  const { triggerAuthFlow } = useAuthFlow();
  const {
    publicConfig: { multiAuthEnabled }
  } = tenant;

  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanReportAbuse: true,
      useCanModerateMessage: true
    },
    IdConverter.isOptimistic(tenant, message?.id)
  );

  const {
    data: communityPolicyData,
    error: communityPolicyError,
    loading: communityPolicyLoading
  } = useCommunityPolicies(module, { useCanReportAbuseDSA: true });

  const [anonymousUserAction, setAnonymousUserAction] = useLocalStorage<AnonymousUserAction>(
    LocalStorageKeys.ANONYMOUS_USER_ACTION_KEY,
    null
  );

  const { ready: ssoPropertiesReady } = useCommunitySsoProperties(module);

  useEffect(() => {
    if (
      anonymousUserAction &&
      anonymousUserAction.action === AnonAction.REPORT_ABUSE &&
      anonymousUserAction.context.messageId === message.id
    ) {
      if (!isPendingActionExpired(anonymousUserAction)) {
        if (
          isFullyRegistered &&
          checkPolicy(policiesData?.message.messagePolicies?.canReportAbuse)
        ) {
          setShowReportTopicModal(true);
        } else if (isPartiallyRegistered) {
          showSsoRegistrationModal(true, () => {
            const userAction: AnonymousUserAction = {
              ...anonymousUserAction,
              timeStamp: Date.now()
            };
            setAnonymousUserAction(userAction);
          });
          setAnonymousUserAction(null);
        }
      } else {
        setAnonymousUserAction(null);
      }
    }
  }, [
    anonymousUserAction,
    isFullyRegistered,
    isPartiallyRegistered,
    message.id,
    policiesData,
    setAnonymousUserAction,
    setShowReportTopicModal,
    showSsoRegistrationModal
  ]);

  if (
    textLoading ||
    routesLoading ||
    policiesLoading ||
    communityPolicyLoading ||
    communityPolicyError
  ) {
    return null;
  }

  const isDSAEnabled = canReportAbuseDSA(communityPolicyData?.coreNode);
  const canReportAbuse = checkPolicy(policiesData?.message.messagePolicies?.canReportAbuse);

  const renderReportTopicModal = (): React.ReactElement => {
    return (
      <ReportAbuseModal
        show={showReportTopicModal}
        onHide={() => {
          setShowReportTopicModal(false);
        }}
        message={message}
        isAnonymousReporting={isAnonymous}
      />
    );
  };

  const handleItemOnClick = async (): Promise<void> => {
    if (isAnonymous) {
      if (isDSAEnabled) {
        setShowReportTopicModal(true);
      } else {
        const userAction: AnonymousUserAction = {
          action: AnonAction.REPORT_ABUSE,
          context: { messageId: message.id },
          timeStamp: Date.now()
        };

        // redirect user to sso login with current page as referer,
        // use permalink in order to ensure message is displayed
        const {
          uid,
          conversation: { topic }
        } = message;

        const { messageReplyPage } = ConversationStyleBehaviorHelper.getInstance(message.board);
        const relativeUrlForRoute = router.getRelativeUrlForRoute<MessageReplyPagesAndParams>(
          messageReplyPage,
          {
            boardId: message.board.displayId,
            messageSubject: UrlHelper.determineSlugForMessagePath(topic),
            messageId: topic.uid.toString(),
            replyId: uid.toString()
          }
        );

        const url = UrlBuilder.fromUrl(window.location.origin).addPath(relativeUrlForRoute).build();

        setAnonymousUserAction(userAction);
        await triggerAuthFlow(
          (ssoEnabled: boolean) => {
            if (!ssoEnabled) {
              setMessageEditingState(null);
            }
          },
          multiAuthEnabled ? AuthFlow.MULTI_AUTH_LOGIN : AuthFlow.LOGIN,
          router.getCurrentRouteAndParams(),
          url
        );
      }
    } else if (isPartiallyRegistered) {
      showSsoRegistrationModal(true, () => {
        const userAction: AnonymousUserAction = {
          action: AnonAction.REPORT_ABUSE,
          context: { messageId: message.id },
          timeStamp: Date.now()
        };
        setAnonymousUserAction(userAction);
      });
    } else {
      setShowReportTopicModal(true);
    }
  };

  return (
    <>
      {(canReportAbuse || isAnonymous || isPartiallyRegistered) && (
        <Dropdown.Item
          onClick={handleItemOnClick}
          disabled={!isFullyRegistered && !ssoPropertiesReady}
          data-testid="ReportAbuseOption"
        >
          {formatMessage('title')}
        </Dropdown.Item>
      )}
      {showReportTopicModal && renderReportTopicModal()}
    </>
  );
};

export default MessageActionReportAbuse;
